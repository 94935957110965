import http from '@/utils/http.js'

// 线索历史
export function historyClueList(data) {
    return http({
        url: "/jasoboss/clue/history/list",
        method: "post",
        data
    })
}

// 客户历史
export function historyCustList(data) {
    return http({
        url: "/jasoboss/customer/history/list",
        method: "post",
        data
    })
}
