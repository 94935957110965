<template>
    <div>
        <div class="text">
            <el-button
                type="primary"
                style="
                    padding: 0;
                    color: #2370eb;
                    background: none;
                    margin-left: -25px;
                    margin-right: 10px;
                "
                ><i class="el-icon-check"></i></el-button
            >确定放弃 {{ data.companyName }}客户吗 ？
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: auto;
                "
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 30px;
                "
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { giveUpCustomer } from '@/api/customer/customer.js';
export default {
    data() {
        return {
            data: {},
        };
    },
    methods: {
        getGiveup(data) {
            this.data = data;
        },
        onSubmit() {
            let data = {
                param: {
                    customerId: this.data.id,
                },
            };
            giveUpCustomer(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                }
                this.onClose2();
            });
        },
        onClose() {
            this.$emit('close');
        },
        onClose2() {
            this.$emit('close2');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.text {
    padding: 38px 0 135px 130px;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
</style>
