<template>
    <div class="dealings">
        <!-- <div class="top_title">
            <span><i>往来</i></span>
            <div class="center_info">
                <p>
                    归属人：{{
                        data.followAdminName ? data.followAdminName : '- -'
                    }}
                </p>
                <p>跟进时间：{{ interTime || '- -' }}</p>
            </div>
            <span> </span>
        </div> -->
        <div class="info">
            <div class="left_box">
                <Add
                    @close="close"
                    v-if="data.id"
                    :data="data"
                    @dealingAdd="dealingAdd"
                ></Add>
            </div>
            <div class="right_box">
                <History
                    v-if="data.id"
                    @close="close"
                    :data="data"
                    ref="history"
                    @intercourseTime="intercourseTime"
                ></History>
            </div>
        </div>
    </div>
</template>
<script>
import Add from './components/add.vue';
import History from './components/history.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            tag: true,
            data: {},
            interTime: '',
        };
    },
    components: {
        Add,
        History,
    },

    methods: {
        getData(data) {
            console.log(data);
            this.data = data;
            this.dealingAdd();
        },
        intercourseTime(intercourseTime) {
            console.log(intercourseTime);
            this.interTime = intercourseTime;
        },
        close() {
            this.$emit('close');
        },
        dealingAdd(data) {
            setTimeout(() => {
                this.$refs.history.dealingList();
            });
        },
        // intercourseFormatter(row) {
        //     if (
        //         row.customerIntercourseList &&
        //         row.customerIntercourseList.length > 0
        //     ) {
        //         return row.customerIntercourseList[0].intercourseTime;
        //     }
        //     return '- -';
        // },
    },
};
</script>

<style scoped>
.dealings {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.dealings .top_title {
    border-bottom: 1px dashed #eeeeee;
    display: flex;
    font-size: 16px;
    padding: 18px 15px 18px 35px;
    width: 100%;
    box-sizing: border-box;
}
.top_title .center_info {
    flex: 1;
    margin-left: 10px;
}
.center_info p:first-child {
    margin-bottom: 8px;
}
.top_title span:first-child {
    color: #fff;
    width: 20px;
    height: 20px;
    background: #2370eb;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.top_title span:first-child i {
    font-size: 16px;
    display: inline-block;
    font-style: normal;
    transform: scale(0.5, 0.5);
    white-space: nowrap;
    line-height: 1.2;
}
.top_title span:last-child {
    cursor: pointer;
}
.dealings .info {
    display: flex;
    flex: 1;
    justify-content: space-between;
    background-color: #f0f2f4;
    height: calc(100% - 87px);
}
.info > div {
    width: 49.5%;
    background-color: #fff;
    height: 100%;
    overflow: hidden;
}
</style>
