<template>
    <div class="source">
        <div class="source_title">
            <i class="iconfont icon-biaoqian"></i>
            关联线索共{{ total }}条
        </div>
        <div class="source_list" v-for="form in data" v-if="total > 0">
            <span class="spot"></span>
            <el-form
                label-width="90px"
                label-position="left"
                size="small"
                style=" padding: 10px 23px;"
            >
                <el-form-item label="线索品牌">
                    <span class="text">{{
                        $tableDataHandle.productCategory(form.productClue)
                    }}</span>
                </el-form-item>
                <el-form-item label="需求">
                    <span class="text">{{ form.remark }}</span>
                </el-form-item>

                <el-form-item label="创建时间">
                    <span class="text">{{ form.createTime }}</span>
                </el-form-item>
                <p class="details_btn">
                    <el-button
                        type="primary"
                        size="mini"
                        @click="onDetails(form)"
                        >查看详情</el-button
                    >
                </p>
            </el-form>
        </div>
    </div>
</template>
<script>
import { clueList } from '@/api/user/clue';
export default {
    data() {
        return {
            data: [{}],
            total: 0,
        };
    },
    methods: {
        getData(row) {
            let data = {
                param: {
                    customerId: row.id,
                    handleStatus: 0,
                },
            };
            clueList(data).then((res) => {
                // console.log(res);
                this.total = res.data.total;
                this.data = res.data.list;
            });
        },
        onDetails(data) {
            // this.$router.push({
            //   path: "/meclue",
            //   query: {
            //     id: data.id,
            //   },
            // });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.source {
    padding: 22px 32px 24px 29px;
    .source_title {
        i {
            font-size: 12px;
        }
        color: #333333;
        font-size: 14px;
    }
    .spot {
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #2370eb;
        border: 2px solid #2370eb;
        border-radius: 50%;
        position: absolute;
        left: -25px;
    }
    .source_list {
        margin-top: 16px;
        background: rgba(35, 112, 235, 0.05);
        border-radius: 4px;
        border: 1px solid #b0c3ff;
        margin-left: 26px;
        position: relative;
    }
    .details_btn {
        text-align: center;
        button {
            background: rgba(35, 112, 235, 0.05);
            border-color: #2370eb;
            color: #2370eb;
        }
    }
}
</style>
