<template>
  <div class="add-tobo">
    <div class="add-form">
      <el-form ref="form" :model="form" label-width="160px">
        <el-form-item label="待办主题">
          <el-input
            v-model="form.todoTitle"
            placeholder="请输入待办标题"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="处理时间" required>
          <el-date-picker
            :picker-options="pickerOptions"
            v-model="form.todoTime"
            type="datetime"
            placeholder="选择日期时间"
            size="small"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width:270px;"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="提醒时间" required>
          <el-select
            v-model="form.remindType"
            size="small"
            style="width:245px"
            placeholder="请选择提醒时间"
            clearable
          >
            <el-option label="15分钟" value="1"></el-option>
            <el-option label="30分钟" value="2"></el-option>
            <el-option label="1小时" value="3"></el-option>
            <el-option label="2小时" value="4"></el-option>
            <el-option label="1天" value="5"></el-option>
          </el-select>
          前
        </el-form-item>
        <el-form-item label="待办内容" required>
          <el-input
            style="width:270px"
            type="textarea"
            placeholder="请输入待办内容"
            v-model="form.todoContent"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="but">
      <el-button
        round
        type="primary"
        size="mini"
        style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
        @click="onClose"
        >取消</el-button
      >
      <el-button
        round
        type="primary"
        style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
        size="mini"
        @click="onSubmit"
        >确定</el-button
      >
    </div>
  </div>
</template>
<script>
import { addtodo } from "@/api/user/custTodo.js";
export default {
  data() {
    return {
      form: {
        todoTitle: "",
        todoContent: "",
        adminName: sessionStorage.getItem("nickName"),
        todoTime: "",
        customerId: this.$formDetails.state.data.id,
        remindType: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
    };
  },
  methods: {
    onSubmit() {
      this.form.customerId = this.$formDetails.state.data.id;
      this.form.adminName = sessionStorage.getItem("nickName");
      if (this.form.todoTime == "") {
        this.$message.error("请选择处理时间");
      } else if (this.form.remindType == "") {
        this.$message.error("请选择提醒时间");
      } else if (this.form.todoContent == "") {
        this.$message.error("请输入代办内容");
      } else {
        let data = {
          param: this.form,
        };
        addtodo(data).then((res) => {
          if (res.code == 200) {
            this.$message.success("添加成功");
          }
          this.onClose();
        });
      }
    },
    onClose() {
      this.$emit("close");
      Object.keys(this.form).forEach((key) => {
        this.form[key] = "";
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.add-tobo {
  width: 100%;
  .add-form {
    padding: 30px 0 70px 0;
  }
  .el-input {
    width: 270px;
    line-height: 32px;
  }
  .but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      width: 72px;
    }
  }
}
</style>
