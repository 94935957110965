import { render, staticRenderFns } from "./giveUp.vue?vue&type=template&id=247176c2&scoped=true&"
import script from "./giveUp.vue?vue&type=script&lang=js&"
export * from "./giveUp.vue?vue&type=script&lang=js&"
import style0 from "./giveUp.vue?vue&type=style&index=0&id=247176c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "247176c2",
  null
  
)

export default component.exports