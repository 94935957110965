<template>
  <div class="details">
    <div class="top_title">
      <span><i>客户</i></span>
      <div class="center_info">
        <p style="font-size:16px">{{ contactData(data) }}</p>
        <p style="font-size:14px">
          负责人：{{ data.followAdminName ? data.followAdminName : "- -" }}
        </p>
      </div>
      <div class="right">
        <el-button
          @click="onGive()"
          v-if="isBtn.editUpdateGive"
          type="text"
          style="color:#666666;padding:0;margin-bottom:10px;text-align: right;"
          size="samll"
          ><p class="el-icon-remove" style="color:#B620E0"></p>
          放弃客户</el-button
        >
        <p style="color:#666666;font-size:14px">
          跟进时间：{{ data.updateTime }}
        </p>
      </div>
    </div>
    <div class="info">
      <div class="left-box">
        <div class="tab-box">
          <span
            class=" iconfont icon-jibenxinxi"
            style="color:#2370EB;margin:0 0 0 30px"
          ></span>
          <p @click="onSwitch(1)" :class="index == 1 ? 'isColor' : ''">
            基本信息
            <span></span>
          </p>
          <p
            @click="onSwitch(2)"
            :class="index == 2 ? 'isColor' : ''"
            v-if="isBtn.editContacts || isBtn.descContacts"
          >
            联系人信息<span></span>
          </p>
          <p
            @click="onSwitch(3)"
            :class="index == 3 ? 'isColor' : ''"
            v-if="isBtn.editCule || isBtn.descClue"
          >
            关联线索<span></span>
          </p>

          <p
            @click="onSwitch(4)"
            :class="index == 4 ? 'isColor' : ''"
            v-if="isBtn.editContract || isBtn.descContract"
          >
            关联合同<span></span>
          </p>
        </div>
        <div class="content-form-box">
          <CustomerForm v-show="index == 1" ref="customerform" />
          <ContactsForm
            v-show="index == 2"
            ref="contactsform"
            @addContacts="addContacts"
            @editContacts="editContacts"
            @deleteContacts="deleteContacts"
          />
          <ClueForm v-show="index == 3" ref="clueform" />
          <ContractList v-show="index == 4" ref="contractlist" />
        </div>
      </div>
      <div class="right-box">
        <el-collapse v-model="activeNames">
          <el-collapse-item
            name="1"
            v-show="isBtn.descStayDo || isBtn.editStayDo"
          >
            <template slot="title">
              <p class="title">
                <span>
                  <span
                    class="iconfont icon-daiban2"
                    style="color:#FF5031;margin:0 14px 0 22px;font-weight: 600;font-size:18px;"
                  ></span>
                  我的待办
                </span>

                <el-button
                  v-if="isBtn.editStayDo"
                  type="primary"
                  size="mini"
                  style="background:none;border-color:#2370EB;color:#2370EB;margin-left:auto;margin-right:16px"
                  @click.stop="onAddTodo()"
                >
                  <i class="el-icon-plus" style="margin-right:10px"></i>添加待办
                </el-button>
              </p>
            </template>
            <Todo ref="todo" />
          </el-collapse-item>
          <el-collapse-item
            name="2"
            v-show="isBtn.descFollowUp || isBtn.editFollowUp"
          >
            <template slot="title">
              <p class="title">
                <span>
                  <span
                    class="iconfont icon-genjin"
                    style="color:#9254DE;margin:0 14px 0 22px;font-weight: 600;font-size:18px;"
                  ></span>
                  跟进计划
                </span>

                <el-button
                  v-if="isBtn.editFollowUp"
                  type="primary"
                  size="mini"
                  style="background:none;border-color:#2370EB;color:#2370EB;margin-left:auto;margin-right:16px"
                  @click.stop="onAddFollow()"
                >
                  <i class="el-icon-plus" style="margin-right:10px"></i>添加计划
                </el-button>
              </p>
            </template>
            <Follow ref="follow" />
          </el-collapse-item>
          <el-collapse-item
            name="3"
            v-show="isBtn.descDealings || isBtn.editDealings"
          >
            <template slot="title">
              <p class="title">
                <span>
                  <span
                    class="iconfont icon-jilu"
                    style="color:#FEB516;margin:0 14px 0 24px"
                  ></span
                  >往来记录
                </span>

                <el-button
                  v-if="isBtn.editDealings"
                  type="primary"
                  size="mini"
                  style="background:none;border-color:#2370EB;color:#2370EB;margin-left:auto;margin-right:16px"
                  @click.stop="onAddDealings()"
                >
                  <i class="el-icon-plus" style="margin-right:10px"></i>添加往来
                </el-button>
              </p>
            </template>
            <Dealings ref="dealings" />
          </el-collapse-item>
          <el-collapse-item name="4">
            <template slot="title">
              <div class="history-title">
                <span
                  class="iconfont icon-lishi"
                  style="color:#2F54EB;margin:0 16px 0 24px"
                ></span>
                客户历史
              </div>
            </template>
            <History ref="history" />
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="bottom-but">
      <span style="margin-left: auto;">
        <el-button
          round
          type="primary"
          size="mini"
          style="background:none;border-color:#D5D6DF;color:#666666;;margin-right:20px"
          @click="onClose"
          >取消</el-button
        >
        <el-button
          v-if="isDisabled"
          round
          type="primary"
          style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:20px;margin-left:0"
          size="mini"
          @click="onSubmit"
          >保存</el-button
        ></span
      >
    </div>
    <!-- 新建联系人 -->
    <el-drawer
      :visible.sync="drawerAddCont"
      :direction="direction"
      :before-close="handleCont"
      size="480px"
      :append-to-body="true"
    >
      <template slot="title">
        <div
          style="display:flex; align-items: center;font-size:16px;font-weight: 500;color: rgba(0, 0, 0, 0.85);line-height: 24px;"
        >
          新建联系人
        </div>
      </template>
      <AddContacts @close="handleCont" />
    </el-drawer>
    <!-- 修改联系人 -->
    <el-drawer
      append-to-body
      :visible.sync="drawerEditCont"
      :direction="direction"
      :before-close="handleCont"
      size="480px"
      :append-to-body="true"
    >
      <template slot="title">
        <div
          style="display:flex; align-items: center;font-size:16px;font-weight: 500;color: rgba(0, 0, 0, 0.85);line-height: 24px;"
        >
          修改联系人
        </div>
      </template>
      <EddContacts @close="handleCont" ref="editcontacts" />
    </el-drawer>

    <!--  添加待办 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="560px"
      :before-close="handleClose"
      append-to-body
    >
      <template slot="title">
        <div
          style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
        >
          添加待办
        </div>
      </template>
      <AddTobo @close="handleClose" />
    </el-dialog>
    <!-- 添加跟进计划 -->
    <el-dialog
      :visible.sync="dialogFollow"
      width="560px"
      :before-close="handleClose"
      append-to-body
    >
      <template slot="title">
        <div
          style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
        >
          添加计划
        </div>
      </template>
      <AddFollow @close="handleClose" />
    </el-dialog>
    <!-- 添加往来 -->
    <el-dialog
      :visible.sync="dialogDealings"
      width="560px"
      :before-close="handleClose"
      append-to-body
    >
      <template slot="title">
        <div
          style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
        >
          添加往来
        </div>
      </template>
      <AddDealings @close="handleClose" />
    </el-dialog>
    <!-- 放弃客户 -->
    <el-dialog
      :visible.sync="dialogGiveup"
      width="560px"
      :before-close="handleGiveup"
      append-to-body
    >
      <template slot="title">
        <div
          style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
        >
          放弃客户
        </div>
      </template>
      <GiveUp ref="giveup" @close="handleGiveup" />
    </el-dialog>
  </div>
</template>
<script>
import { editCustomer } from "@/api/user/ae/customer";
import CustomerForm from "./customerForm/customerForm.vue";
import ContactsForm from "./contacts-details/contactsForm.vue";
import AddContacts from "./contacts-details/addContacts.vue"; //新建联系人
import EddContacts from "./contacts-details/editContacts.vue"; //修改联系人
import ClueForm from "./clueForm/clueForm.vue";
import ContractList from "./contractList/contractList.vue";

import Todo from "../../components/details/todo/todo.vue"; //代办
import Follow from "../../components/details/followUp/followUp.vue"; //跟进计划
import Dealings from "../../components/details/dealings/dealings.vue"; //往来
import History from "../../components/details/history/history.vue"; //历史

import AddTobo from "../../components/details/todo/addTobo.vue";
import AddFollow from "../../components/details/followUp/addFollow.vue";
import AddDealings from "../../components/details/dealings/addDealings.vue";

import GiveUp from "../meCustomer/table/giveUp.vue";
export default {
  components: {
    CustomerForm,
    ContactsForm,
    ClueForm,
    ContractList,
    Todo,
    Follow,
    Dealings,
    History,
    AddContacts,
    EddContacts,
    AddTobo,
    AddFollow,
    AddDealings,
    GiveUp,
  },
  data() {
    return {
      index: 1,
      data: {},
      isBtn: {},
      isDisabled: "",
      drawerAddCont: false,
      drawerEditCont: false,
      direction: "rtl",
      activeNames: ["1", "2", "3", "4"],
      dialogVisible: false,
      dialogFollow: false,
      dialogDealings: false,
      drawerAddEstimate: false,
      drawerEditEstimate: false,
      dialogGiveup: false,
    };
  },
  methods: {
    getData(row, isBtn, isDisabled) {
      this.index = 1;
      this.data = row;
      this.isBtn = isBtn;
      this.isDisabled = isDisabled;
      setTimeout(() => {
        this.$refs.customerform.getData(row, isDisabled);
        this.$refs.todo.getTodoList(isBtn);
        this.$refs.follow.getFollowUp(isBtn);
        this.$refs.dealings.getDealings(isBtn);
        this.$refs.history.getData();
      }, 100);
    },
    onSubmit() {
      let customerDO = this.$refs.customerform.customerDO;
      let address = this.$refs.customerform.address;
      let customerDomainList = this.$refs.customerform.customerDomainList;
      let customerTagList = this.$refs.customerform.customerTagList;
      if (address.length > 0) {
        customerDO.provinceId = address[0];
        customerDO.cityId = address[1];
      }
      if (customerDO.business && customerDO.business.length > 0) {
        customerDO.business = customerDO.business[0];
      }

      if (customerDO.companyName == "") {
        return this.$message.error("请输入公司名称");
      }

      customerDO.id = this.$formDetails.state.data.id;
      let data = {
        param: {
          customerDO: customerDO,
          customerDomainList: customerDomainList,
        },
      };
      editCustomer(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.$emit("close");
        }
      });
    },
    onClose() {
      this.$emit("close");
    },
    contactData(data) {
      if (data.customerContactList && data.customerContactList.length > 0) {
        return data.customerContactList[0].contactName;
      }
      return "- -";
    },
    onSwitch(i) {
      this.index = i;
      if (i == 1) {
        this.$refs.customerform.getData(this.data, this.isDisabled);
      }
      if (i == 2) {
        this.$refs.contactsform.getData(this.data, this.isDisabled);
      }
      if (i == 3) {
        this.$refs.clueform.getData(this.data, this.isDisabled);
      }
      if (i == 4) {
        this.$refs.contractlist.getData(this.data);
      }
    },
    // 放弃客户
    onGive() {
      this.dialogGiveup = true;
      setTimeout(() => {
        this.$refs.giveup.getGiveup(this.data);
      }, 50);
    },
    handleGiveup() {
      this.dialogGiveup = false;
      this.onClose();
    },
    // 添加联系人
    addContacts() {
      this.drawerAddCont = true;
    },
    // 修改联系人
    editContacts(data) {
      this.drawerEditCont = true;
      setTimeout(() => {
        this.$refs.editcontacts.getData(data);
      }, 50);
    },
    handleCont() {
      this.drawerAddCont = false;
      this.drawerEditCont = false;
      if (sessionStorage.getItem("storage")) {
        let data = JSON.parse(sessionStorage.getItem("storage"));
        this.$formDetails.commit("detailsData", JSON.stringify(data));
        sessionStorage.removeItem("storage");
      }
      this.$refs.contactsform.getData(this.data, this.isDisabled);
    },
    deleteContacts() {
      this.$refs.contactsform.getData(this.data, this.isDisabled);
    },
    // 添加往来
    onAddDealings() {
      this.dialogDealings = true;
    },
    // 添加跟进
    onAddFollow() {
      this.dialogFollow = true;
    },
    // 添加代办
    onAddTodo() {
      this.dialogVisible = true;
    },
    handleClose() {
      // this.drawerAddEstimate = false;
      // this.drawerEditEstimate = false;
      this.dialogVisible = false;
      this.dialogFollow = false;
      this.dialogDealings = false;
      // if (this.userType != 2 && this.userType != 3) {
      //     this.$refs.estimate.getData();
      // }
      setTimeout(() => {
        this.$refs.todo.getTodoList(this.isBtn);
        this.$refs.follow.getFollowUp(this.isBtn);
        this.$refs.dealings.getDealings(this.isBtn);
      }, 50);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.details {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.details .top_title {
  border-bottom: 1px dashed #eeeeee;
  display: flex;
  font-size: 16px;
  padding: 18px 15px 18px 35px;
  width: 100%;
  box-sizing: border-box;
  color: #666666;
}
.top_title .center_info {
  flex: 1;
  margin-left: 10px;
}
.center_info p:first-child {
  margin-bottom: 8px;
}
.right {
  display: flex;
  flex-direction: column;
}
.top_title span:first-child {
  color: #fff;
  width: 20px;
  height: 20px;
  background: #2370eb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top_title span:first-child i {
  font-size: 16px;
  display: inline-block;
  font-style: normal;
  transform: scale(0.5, 0.5);
  white-space: nowrap;
  line-height: 1.2;
}
.top_title span:last-child {
  cursor: pointer;
}
.details .info {
  display: flex;
  flex: 1;
  justify-content: space-between;
  background-color: #f0f2f4;
  height: calc(100% - 87px);
  overflow: auto;
}

.left-box {
  flex: 1;
  background: #fff;
  border-bottom: 1px solid #dee0e3;
  display: flex;
  flex-direction: column;

  width: 49%;
  .tab-box {
    display: flex;
    align-items: center;
    height: 59px;
    border-bottom: 1px solid #eee;
    overflow: auto;
    p {
      cursor: pointer;
      font-weight: 500;
      color: #666666;
      font-size: 14px;
      padding: 0 16px;
      height: 100%;
      line-height: 59px;
      overflow: hidden;
      /*文本不会换行*/
      white-space: nowrap;
      /*当文本溢出包含元素时，以省略号表示超出的文本*/
      text-overflow: ellipsis;
    }
    .isColor {
      color: #2370eb;
      position: relative;
      span {
        display: block;
        width: 16px;
        height: 3px;
        background: #2370eb;
        border-radius: 1px 1px 0px 0px;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -8px;
      }
    }
  }
  .content-form-box {
    flex: 1;
    overflow: auto;
  }
}
.right-box {
  width: 49.5%;
  flex: 1;
  background: #fff;
  margin-left: 16px;
  border-bottom: 1px solid #dee0e3;
  overflow: auto;
  .title {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #666666;
  }
  .history-title {
    font-size: 14px;
    font-weight: 500;
    color: #666666;
    padding: 17px 0;
    display: flex;
    align-content: center;
  }
}
.bottom-but {
  width: 100%;
  height: 48px;
  background: #fff;
  display: flex;
  align-items: center;
  border-top: 1px solid #e5e5e5;
  button {
    width: 90px;
  }
}
</style>
