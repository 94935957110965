var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contractList"},_vm._l((_vm.contractList),function(item){return _c('div',{staticStyle:{"padding-bottom":"50px"}},[_c('div',{staticClass:"contract"},[_c('div',{staticClass:"title"},[_c('span',[_c('i',{staticClass:"iconfont icon-contract-fill"}),_vm._v(" "+_vm._s(item.contractTitle)+" ")])]),_c('p',[_c('span',[_vm._v("甲方")]),_c('span',[_vm._v(_vm._s(item.contractA))])]),_c('p',[_c('span',[_vm._v("打款人")]),_c('span',[_vm._v(_vm._s(item.payContract))])]),_c('p',[_c('span',[_vm._v("乙方")]),(item.typeB != 9)?_c('span',[_vm._v(" "+_vm._s(_vm.$tableDataHandle.typeB(item.typeB))+" ")]):_vm._e(),(item.typeB == 9)?_c('span',[_vm._v(_vm._s(item.contractB))]):_vm._e()]),_c('p',[_c('span',[_vm._v("合同金额")]),_c('span',[_vm._v(_vm._s(item.contractAmount))])]),_c('p',[_c('span',{staticStyle:{"vertical-align":"top"}},[_vm._v("营业执照")]),_c('span',[(
                            item.businessLicenseUrl.indexOf('.jpg') != -1 ||
                                item.businessLicenseUrl.indexOf('.png') !=
                                    -1 ||
                                item.businessLicenseUrl.indexOf('.gif') !=
                                    -1
                        )?_c('el-image',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":item.businessLicenseUrl,"preview-src-list":[item.businessLicenseUrl]}}):_vm._e(),(
                            item.businessLicenseUrl.indexOf('.pdf') != -1 ||
                                item.businessLicenseUrl.indexOf('.PDF') !=
                                    -1
                        )?_c('span',{staticClass:"pdf",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onPreview(item.businessLicenseUrl)}}},[_c('img',{attrs:{"src":require("@/img/pdfUrl.png"),"alt":""}})]):_vm._e(),(item.businessLicenseUrl.indexOf('.doc') != -1)?_c('span',{staticClass:"word",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onPreview(item.businessLicenseUrl)}}},[_c('img',{attrs:{"src":require("@/img/word.png"),"alt":""}})]):_vm._e()],1)]),_c('p',[_c('span',{staticStyle:{"vertical-align":"top"}},[_vm._v("附件")]),_vm._l((item.contractAttachmentList),function(item){return _c('span',[(
                            item.attachmentUrl.indexOf('.jpg') != -1 ||
                                item.attachmentUrl.indexOf('.png') != -1 ||
                                item.attachmentUrl.indexOf('.gif') != -1
                        )?_c('el-image',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":item.attachmentUrl,"preview-src-list":[item.attachmentUrl]}}):_vm._e(),(
                            item.attachmentUrl.indexOf('.pdf') != -1 ||
                                item.attachmentUrl.indexOf('.PDF') != -1
                        )?_c('span',{staticClass:"pdf",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onPreview(item.attachmentUrl)}}},[_c('img',{attrs:{"src":require("@/img/pdfUrl.png"),"alt":""}})]):_vm._e(),(item.attachmentUrl.indexOf('.doc') != -1)?_c('span',{staticClass:"word",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onPreview(item.attachmentUrl)}}},[_c('img',{attrs:{"src":require("@/img/word.png"),"alt":""}})]):_vm._e()],1)})],2),_c('p',[_c('span',[_vm._v("备注")]),_c('span',[_vm._v(" "+_vm._s(item.remark))])])]),(item.orderList.length > 0)?_c('p',{staticClass:"order-title"},[_c('i',{staticClass:"iconfont icon-dilanxianxingiconyihuifu_huabanfuben"}),_vm._v(" 关联订单 ")]):_vm._e(),_c('div',{staticClass:"order"},_vm._l((item.orderList),function(data,index){return (item.orderList.length > 0)?_c('el-collapse',{key:index},[_c('el-collapse-item',{attrs:{"title":_vm.title(data),"name":index}},[_c('el-form',{staticStyle:{"flex":"1","margin-top":"20px"},attrs:{"label-position":"left","label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"邮箱版本"}},[(data.productCategory == 1)?_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.version == 1 ? '老版' : data.version == 2 ? '新版' : '- -'))]):_vm._e(),(data.productCategory == 2)?_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.version == 1 ? '标准版' : data.version == 2 ? '尊享版' : data.version == 3 ? '集团版' : '- -'))]):_vm._e()]),_c('el-form-item',{attrs:{"label":"订单类型"}},[(
                                    data.productCategory == 1 ||
                                        data.productCategory == 2 ||
                                        data.productCategory == 10
                                )?_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.version == 1 ? '新增' : data.version == 2 ? '续费' : data.version == 3 ? '扩用户' : '- -'))]):_vm._e(),(data.productCategory == 3)?_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.version == 1 ? '腾讯云' : data.version == 2 ? '阿里云' : '- -'))]):_vm._e()]),_c('el-form-item',{attrs:{"label":"用户数"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.orderNumber))])]),_c('el-form-item',{attrs:{"label":"购买年限"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.orderTerm))])]),_c('el-form-item',{attrs:{"label":"增补年限"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.supplementTerm))])]),_c('el-form-item',{attrs:{"label":"下单年限"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.totalTerm))])]),(data.productCategory == 10)?_c('el-form-item',{attrs:{"label":"vip账号金额"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.vipAmount))])]):_vm._e(),(data.productCategory == 10)?_c('el-form-item',{attrs:{"label":"系统金额"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.systemAmount))])]):_vm._e(),_c('el-form-item',{attrs:{"label":"合计金额"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.amount))])]),_c('el-form-item',{attrs:{"label":"交付状态"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.status == 1 ? '未交付' : data.status == 2 ? '部分交付' : data.status == 3 ? '已交付' : '- -'))])]),_c('el-form-item',{attrs:{"label":"备注"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.remark))])])],1)],1)],1):_vm._e()}),1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }