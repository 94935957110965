<template>
  <div class="todo-box">
    <div class="todo-list" v-for="item in dataList">
      <div class="one" @click="onEdit(item)" style="cursor: pointer;">
        <p>
          <span class="yuan"></span> <span>待办时间</span>{{ item.todoTime }}
        </p>
        <p
          v-if="item.remindStatus == 1"
          style="width:50%;height:20px;margin-right:20px;text-align:right;color:#333333"
        >
          <i class="iconfont icon-daiban1"></i>
          已提醒
        </p>
        <p
          v-if="item.remindStatus == 0"
          style="width:50%;height:auto;margin-right:20px;text-align:right;color:#333333"
        >
          <span style="width:100%">
            <i class="iconfont icon-daiban1" style="color:#2370EB"></i>
            提前{{
              item.remindType == 1
                ? "15分钟"
                : item.remindType == 2
                ? "30分钟"
                : item.remindType == 3
                ? "1小时"
                : item.remindType == 4
                ? "2小时"
                : item.remindType == 5
                ? "1天"
                : " - -"
            }}</span
          >
        </p>
      </div>
      <div>
        <p><span>创建人</span>{{ item.adminName }}</p>
      </div>
      <div>
        <p><span>待办标题</span>{{ item.todoTitle }}</p>
      </div>
      <div>
        <p><span>待办内容</span>{{ item.todoContent }}</p>
      </div>
      <div class="bot">
        <p></p>

        <p style="color:#2370EB;margin-right:24px">
          <!-- <el-button type="text" style="color:#2370EB" @click="onEdit(item)"
                        >编辑</el-button
                    >
                    丨 -->
          <el-button
            v-if="btnP.editStayDo"
            type="text"
            style="color:#2370EB;margin-left:0"
            @click="onDelete(item)"
            >删除</el-button
          >
        </p>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogEdit"
      width="560px"
      :before-close="handleClose"
      append-to-body
    >
      <template slot="title">
        <div
          style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
        >
          修改待办
        </div>
      </template>
      <EditTobo ref="edittodo" @close="handleClose" />
    </el-dialog>
    <!-- 二次确认 -->
    <el-dialog
      :visible.sync="dialog"
      width="560px"
      :before-close="handle"
      append-to-body
    >
      <template slot="title">
        <div
          style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
        >
          {{ dialogTitle }}
        </div>
      </template>
      <div class="dialog-box">
        <p v-if="dialogType == 2">是否将该条代办删除？删除后不可恢复！</p>
      </div>
      <div class="but">
        <el-button
          round
          type="primary"
          size="mini"
          style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
          @click="dialog = false"
          >取消</el-button
        >
        <el-button
          round
          type="primary"
          style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
          size="mini"
          @click="onSubmit"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import EditTobo from "./editTobo.vue";
import { todoList, deletetodo, statusEdit } from "@/api/user/custTodo.js";
export default {
  components: {
    EditTobo,
  },
  data() {
    return {
      dataList: [],
      dialogVisible: false,
      dialogEdit: false,
      dialogTitle: "",
      dialog: false,
      dialogType: 0,
      id: "",
      btnP: "",
    };
  },

  methods: {
    getTodoList(btnP) {
      this.btnP = btnP;
      let data = {
        param: {
          customerId: this.$formDetails.state.data.id,
        },
      };
      todoList(data).then((res) => {
        this.dataList = res.data.list;
      });
    },

    onEdit(item) {
      if (this.btnP.editStayDo) {
        this.dialogEdit = true;
        let that = this;
        setTimeout(() => {
          that.$refs.edittodo.getData(item);
        }, 200);
      }
    },
    // 已完成
    onComplete(item) {
      this.dialogTitle = "标记为已完成";
      this.dialog = true;
      this.dialogType = 1;
      this.id = item.id;
    },

    // 删除
    onDelete(item) {
      this.dialogTitle = "是否删除";
      this.dialog = true;
      this.dialogType = 2;
      this.id = item.id;
    },
    onSubmit() {
      let data = {
        param: {
          customerId: this.$formDetails.state.data.id,
          id: this.id,
        },
      };
      deletetodo(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("删除成功");
        }
        this.handle();
      });
    },
    handle() {
      this.dialog = false;
      this.getTodoList(this.btnP);
    },
    handleClose() {
      this.dialogEdit = false;
      this.getTodoList(this.btnP);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.todo-box {
  padding-left: 60px;
  .todo-list div {
    padding-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      display: inline-block;
      width: 90px;
    }
  }
  .todo-list .one {
    display: flex;
    p:nth-child(1) {
      position: relative;
      width: 300px;
      .yuan {
        position: absolute;
        display: block;
        width: 8px;
        height: 8px;
        background: #2370eb;
        border: 2px solid #2370eb;
        left: -30px;
        top: 6px;
        border-radius: 50%;
      }
    }
  }
  .todo-list .bot {
    p:nth-child(1) {
      position: relative;
      color: #2370eb;
      span:nth-child(1) {
        position: absolute;
        left: -30px;
        top: 2px;
        font-size: 16px;
        border-radius: 50%;
        color: #2370eb;
        cursor: pointer;
      }
    }
  }
}
.dialog-box {
  padding: 80px 0;
  text-align: center;
  color: #333;
  font-size: 16px;
}
.but {
  width: 100%;
  height: 48px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    width: 72px;
  }
}
</style>
