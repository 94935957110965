import http from '@/utils/http.js'

// 待办列表
export function todoList(data) {
    return http({
        url: "/jasoboss/customer/todo/list",
        method: "post",
        data
    })
}
// 添加代办

export function addtodo(data) {
    return http({
        url: "/jasoboss/customer/todo/add",
        method: "post",
        data
    })
}
// 修改代办
export function edittodo(data) {
    return http({
        url: "/jasoboss/customer/todo/update",
        method: "post",
        data
    })
}
// 删除代办
export function deletetodo(data) {
    return http({
        url: "/jasoboss/customer/todo/delete",
        method: "post",
        data
    })
}
// 修改状态
export function edittodoStauts(data) {
    return http({
        url: "/jasoboss/customer/todo/update/status",
        method: "post",
        data
    })
}

