<template>
    <div class="history">
        <div class="title">
            <i class="el-icon-s-order"></i>
            往来记录
        </div>
        <div class="history_list" v-if="historyData.length > 0">
            <div class="item" v-for="item in historyData" :key="item.id">
                <span class="add_explain" @click="showDialog(item.id)"
                    >添加说明</span
                >
                <p>
                    <span>往来时间</span><span>{{ item.intercourseTime }}</span>
                </p>
                <p>
                    <span>往来标题</span
                    ><span>{{ item.intercourseTitle }}</span>
                </p>
                <p>
                    <span>跟进人</span><span>{{ item.adminName }}</span>
                </p>
                <p>
                    <span>联系方式</span
                    ><span>{{
                        item.contactWay == 1
                            ? '电话'
                            : item.contactWay == 2
                            ? '微信'
                            : item.contactWay == 3
                            ? '邮箱'
                            : item.contactWay == 4
                            ? 'QQ'
                            : item.contactWay == 5
                            ? '短信'
                            : item.contactWay == 6
                            ? '拜访'
                            : item.contactWay == 7
                            ? '其他'
                            : item.contactWay == 8
                            ? '官网表单'
                            : item.contactWay == 9
                            ? '座机'
                            : '- -'
                    }}</span>
                </p>
                <p>
                    <span>往来内容</span
                    ><span>{{ item.intercourseContent }}</span>
                </p>
                <p v-for="itm in item.describeList" :key="itm.id">
                    <span>往来说明</span><span>{{ itm.describeContent }}</span>
                </p>
            </div>
        </div>
        <div class="empty" v-if="historyData.length == 0">
            <img src="@/assets/zanwushuju.png" alt="" />
            <span style="display: block">暂无数据～</span>
        </div>
        <!-- 往来添加说明 -->
        <el-dialog
            :visible.sync="dialog"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dialog"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    添加说明
                </div>
            </template>
            <el-form label-width="70px" label-position="left" size="small">
                <el-form-item label="说明内容">
                    <el-input
                        type="textarea"
                        rows="8"
                        v-model="describeContent"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog_footer">
                <el-button @click="dialog = false" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    @click="addExplain"
                    round
                    size="mini"
                    style="border-color: #2370eb; background: #2370eb"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { dealingList, dealingDesAdd } from '@/api/customer/customer';
export default {
    name: '',
    props: ['data'],
    data() {
        return {
            historyData: [],
            describeContent: '',
            dialog: false,
            describeId: '',
        };
    },
    mounted() {
        // this.dealingList();
    },
    methods: {
        dealingList() {
            let data = {
                param: {
                    customerId: this.data.id,
                },
            };
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            if (btn.searchUser && !btn.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            dealingList(data).then((res) => {
                if (res.code == 200) {
                    this.historyData = res.data.list;
                    if (res.data.list.length > 0) {
                        setTimeout(() => {
                            this.$emit(
                                'intercourseTime',
                                res.data.list[0].intercourseTime
                            );
                        }, 0);
                    }
                }
            });
        },
        showDialog(id) {
            this.dialog = true;
            this.describeId = id;
        },
        addExplain() {
            if (!this.describeContent) {
                return this.$message.error('请输入说明');
            }
            let data = {
                param: {
                    intercourseId: this.describeId,
                    describeContent: this.describeContent,
                },
            };
            dealingDesAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('添加成功');
                    this.handleClose();
                    this.dealingList();
                }
            });
        },
        handleClose() {
            this.describeContent = '';
            this.dialog = false;
        },
    },
};
</script>

<style scoped>
.history {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
}
.title {
    height: 50px;
    line-height: 50px;
    padding-left: 28px;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #eeeeee;
}
.title i {
    color: #feb516;
    margin-right: 10px;
}
.history_list {
    flex: 1;
    overflow-y: auto;
    font-size: 14px;
    color: #333;
}
.history_list .item {
    padding: 15px 40px 0;
    border-bottom: 1px dashed #eeeeee;
    position: relative;
}
.history_list .item:last-child {
    border-bottom: none;
}
.history_list .item p span:first-child {
    color: #666;
    margin-right: 20px;
    display: block;
    width: 60px;
    white-space: nowrap;
}
.history_list .item p {
    margin-bottom: 15px;
    display: flex;
}
.history_list .item p span:last-child {
    flex: 1;
    word-break: break-all;
}
.add_explain {
    position: absolute;
    right: 20px;
    top: 10px;
    display: block;
    font-size: 12px;
    color: #ffa802;
    padding: 5px 10px;
    border: 1px solid #ffa802;
    cursor: pointer;
    user-select: none;
    border-radius: 2px;
}
.dialog .el-form {
    width: 65%;
    margin: 20px auto 150px;
}
.dialog >>> .el-textarea__inner {
    resize: none;
}
.empty {
    text-align: center;
    padding-top: 50px;
}
</style>
