<template>
    <div class="contractList">
        <div v-for="item in contractList" style="  padding-bottom: 50px;">
            <div class="contract">
                <div class="title">
                    <span>
                        <i class="iconfont icon-contract-fill"></i>
                        {{ item.contractTitle }}
                    </span>
                    <!-- <el-button type="primary" size="mini" @click="onContract(item)"
                        >查看详情</el-button
                    > -->
                </div>
                <p>
                    <span>甲方</span>
                    <span>{{ item.contractA }}</span>
                </p>
                <p>
                    <span>打款人</span>
                    <span>{{ item.payContract }}</span>
                </p>
                <p>
                    <span>乙方</span>
                    <span v-if="item.typeB != 9">
                        {{ $tableDataHandle.typeB(item.typeB) }}
                    </span>
                    <span v-if="item.typeB == 9">{{ item.contractB }}</span>
                </p>
                <p>
                    <span>合同金额</span>
                    <span>{{ item.contractAmount }}</span>
                </p>
                <p>
                    <span style="vertical-align:top;">营业执照</span>
                    <span>
                        <el-image
                            v-if="
                                item.businessLicenseUrl.indexOf('.jpg') != -1 ||
                                    item.businessLicenseUrl.indexOf('.png') !=
                                        -1 ||
                                    item.businessLicenseUrl.indexOf('.gif') !=
                                        -1
                            "
                            style="width: 100px; height: 100px"
                            :src="item.businessLicenseUrl"
                            :preview-src-list="[item.businessLicenseUrl]"
                        >
                        </el-image>
                        <span
                            class="pdf"
                            v-if="
                                item.businessLicenseUrl.indexOf('.pdf') != -1 ||
                                    item.businessLicenseUrl.indexOf('.PDF') !=
                                        -1
                            "
                            @click="onPreview(item.businessLicenseUrl)"
                            style="cursor: pointer;"
                        >
                            <img src="@/img/pdfUrl.png" alt=""
                        /></span>
                        <span
                            class="word"
                            v-if="item.businessLicenseUrl.indexOf('.doc') != -1"
                            @click="onPreview(item.businessLicenseUrl)"
                            style="cursor: pointer;"
                        >
                            <img src="@/img/word.png" alt="" />
                        </span>
                    </span>
                </p>

                <p>
                    <span style="vertical-align:top;">附件</span>
                    <span v-for="item in item.contractAttachmentList">
                        <el-image
                            v-if="
                                item.attachmentUrl.indexOf('.jpg') != -1 ||
                                    item.attachmentUrl.indexOf('.png') != -1 ||
                                    item.attachmentUrl.indexOf('.gif') != -1
                            "
                            style="width: 100px; height: 100px"
                            :src="item.attachmentUrl"
                            :preview-src-list="[item.attachmentUrl]"
                        >
                        </el-image>
                        <span
                            class="pdf"
                            v-if="
                                item.attachmentUrl.indexOf('.pdf') != -1 ||
                                    item.attachmentUrl.indexOf('.PDF') != -1
                            "
                            @click="onPreview(item.attachmentUrl)"
                            style="cursor: pointer;"
                        >
                            <img src="@/img/pdfUrl.png" alt="" />
                        </span>
                        <span
                            class="word"
                            v-if="item.attachmentUrl.indexOf('.doc') != -1"
                            @click="onPreview(item.attachmentUrl)"
                            style="cursor: pointer;"
                        >
                            <img src="@/img/word.png" alt="" />
                        </span>
                    </span>
                </p>
                <p>
                    <span>备注</span>
                    <span> {{ item.remark }}</span>
                </p>
            </div>
            <p class="order-title" v-if="item.orderList.length > 0">
                <i
                    class="iconfont icon-dilanxianxingiconyihuifu_huabanfuben"
                ></i>
                关联订单
            </p>
            <div class="order">
                <el-collapse
                    v-if="item.orderList.length > 0"
                    v-for="(data, index) in item.orderList"
                    :key="index"
                >
                    <el-collapse-item :title="title(data)" :name="index">
                        <el-form
                            style="flex:1;margin-top:20px;"
                            label-position="left"
                            label-width="120px"
                        >
                            <el-form-item label="邮箱版本">
                                <span
                                    class="text"
                                    v-if="data.productCategory == 1"
                                    >{{
                                        data.version == 1
                                            ? '老版'
                                            : data.version == 2
                                            ? '新版'
                                            : '- -'
                                    }}</span
                                >
                                <span
                                    class="text"
                                    v-if="data.productCategory == 2"
                                    >{{
                                        data.version == 1
                                            ? '标准版'
                                            : data.version == 2
                                            ? '尊享版'
                                            : data.version == 3
                                            ? '集团版'
                                            : '- -'
                                    }}</span
                                >
                            </el-form-item>

                            <el-form-item label="订单类型">
                                <span
                                    class="text"
                                    v-if="
                                        data.productCategory == 1 ||
                                            data.productCategory == 2 ||
                                            data.productCategory == 10
                                    "
                                    >{{
                                        data.version == 1
                                            ? '新增'
                                            : data.version == 2
                                            ? '续费'
                                            : data.version == 3
                                            ? '扩用户'
                                            : '- -'
                                    }}</span
                                >

                                <span
                                    class="text"
                                    v-if="data.productCategory == 3"
                                    >{{
                                        data.version == 1
                                            ? '腾讯云'
                                            : data.version == 2
                                            ? '阿里云'
                                            : '- -'
                                    }}</span
                                >
                            </el-form-item>
                            <el-form-item label="用户数">
                                <span class="text">{{ data.orderNumber }}</span>
                            </el-form-item>
                            <el-form-item label="购买年限">
                                <span class="text">{{ data.orderTerm }}</span>
                            </el-form-item>
                            <el-form-item label="增补年限">
                                <span class="text">{{
                                    data.supplementTerm
                                }}</span>
                            </el-form-item>
                            <el-form-item label="下单年限">
                                <span class="text">{{ data.totalTerm }}</span>
                            </el-form-item>
                            <el-form-item
                                label="vip账号金额"
                                v-if="data.productCategory == 10"
                                ><span class="text">{{ data.vipAmount }}</span>
                            </el-form-item>
                            <el-form-item
                                label="系统金额"
                                v-if="data.productCategory == 10"
                                ><span class="text">{{
                                    data.systemAmount
                                }}</span>
                            </el-form-item>
                            <el-form-item label="合计金额">
                                <span class="text">{{ data.amount }}</span>
                            </el-form-item>
                            <el-form-item label="交付状态">
                                <span class="text">{{
                                    data.status == 1
                                        ? '未交付'
                                        : data.status == 2
                                        ? '部分交付'
                                        : data.status == 3
                                        ? '已交付'
                                        : '- -'
                                }}</span>
                            </el-form-item>
                            <el-form-item label="备注">
                                <span class="text">{{ data.remark }}</span>
                            </el-form-item>
                        </el-form>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
    </div>
</template>
<script>
import { customerId } from '@/api/user/ae/customer';
export default {
    data() {
        return {
            contractList: [],
            businessLicenseUrl: '',
            attachmentUrl: '',
        };
    },

    methods: {
        getData() {
            let data = {
                param: {
                    customerId: this.$formDetails.state.data.id,
                },
            };
            customerId(data).then((res) => {
                this.contractList = res.data.list;
            });
        },
        onContract(item) {
            this.$router.push('/aecontractdetails');
            this.$formDetails.commit('detailsData', JSON.stringify(item));
        },
        onOrder(item) {
            this.$router.push('/aemeorder');
        },
        title(data) {
            if (data.productCategory == 1) {
                return '腾讯企业邮箱';
            } else if (data.productCategory == 2) {
                return '阿里企业邮箱';
            } else if (data.productCategory == 3) {
                return '云产品';
            } else if (data.productCategory == 4) {
                return '网易企业邮箱';
            } else if (data.productCategory == 5) {
                return '网站';
            } else if (data.productCategory == 6) {
                return '投屏盒子';
            } else if (data.productCategory == 7) {
                return '微盘';
            } else if (data.productCategory == 8) {
                return '主机';
            } else if (data.productCategory == 9) {
                return '服务器';
            } else if (data.productCategory == 10) {
                return '会话存档';
            } else if (data.productCategory == 11) {
                return '企业微信';
            } else {
                return '- -';
            }
        },
        onPreview(url) {
            if (url.indexOf('.doc') != -1 || url.indexOf('.docx') != -1) {
                window.open(
                    'https://view.officeapps.live.com/op/view.aspx?src=' + url
                );
            } else {
                window.open(url, '_blank');
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.contractList {
    width: 100%;
    height: 100%;
    .contract {
        padding: 0 25px 0 60px;
        p {
            font-size: 14px;
            padding-bottom: 16px;
            span:nth-child(1) {
                display: inline-block;
                width: 90px;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
                margin-left: 15px;
            }
            span:nth-child(2) {
                font-weight: 400;
                color: #333333;
                line-height: 20px;
            }
        }
    }
    .order-title {
        padding-left: 60px;
        font-size: 14px;
        i {
            color: #2166d0;
        }
    }
    .title {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        padding: 15px 0 24px 0;
        span {
            font-weight: 500;
            color: #666666;
            line-height: 20px;
        }
        i {
            color: #ff7a44;
            font-size: 18px;
        }
        button {
            background: none;
            border-color: #2370eb;
            color: #2370eb;
        }
    }
    .order {
        padding-left: 76px;
        margin-top: 15px;
        .el-form-item {
            margin-bottom: 0;
        }
    }
}
</style>
