<template>
  <div class="Dealings-box">
    <div class="add-but">
      <div v-for="(item, index) in dealingsList" class="Dealings_list">
        <el-button
          v-if="btnP.editDealings"
          type="primary"
          size="mini"
          style="background:none;border-color:#2370EB;color:#2370EB;;margin-bottom:16px;margin-top:16px"
          @click="onAddExplain(index, item)"
        >
          <i class="el-icon-plus" style="margin-right:10px"></i>添加说明
        </el-button>
        <div class="line">
          <span>往来时间</span>
          <span>{{ item.intercourseTime }}</span>
          <span class="Circle"></span>
        </div>
        <div class="line">
          <span>往来标题</span>
          <span>{{ item.intercourseTitle }}</span>
        </div>
        <div class="line">
          <span>跟进人</span>
          <span>{{ item.adminName }} </span>
        </div>
        <div class="line">
          <span>联系方式</span>
          <span
            >{{
              item.contactWay == 1
                ? "电话"
                : item.contactWay == 2
                ? "微信"
                : item.contactWay == 3
                ? "邮箱"
                : item.contactWay == 4
                ? "QQ"
                : item.contactWay == 5
                ? "短信"
                : item.contactWay == 6
                ? "拜访"
                : item.contactWay == 7
                ? "其他"
                : item.contactWay == 8
                ? "官网表单"
                : item.contactWay == 9
                ? "座机"
                : "- -"
            }}
          </span>
        </div>
        <div class="line">
          <span>往来内容</span>
          <span>{{ item.intercourseContent }}</span>
        </div>
        <div class="describe" v-for="(remark, index) in item.describeList">
          <div>
            <span>说明</span>
            <span>{{ remark.describeContent }}</span>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisibles"
      width="560px"
      :before-close="handleClose"
      append-to-body
    >
      <template slot="title">
        <div
          style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
        >
          添加说明
        </div>
      </template>
      <AddExplain ref="explain" @close="handleClose" />
    </el-dialog>
  </div>
</template>
<script>
import AddDealings from "./addDealings.vue";
import AddExplain from "./addExplain.vue";
import { dealingsList, dealingsAdd } from "@/api/user/custDealings.js";
export default {
  components: {
    AddDealings,
    AddExplain,
  },
  data() {
    return {
      dialogVisibles: false,
      nickName: sessionStorage.getItem("nickName"),
      dealingsList: [],
      btnP: "",
    };
  },

  methods: {
    // 获取往来记录
    getDealings(btnP) {
      this.btnP = btnP;
      let data = {
        param: {
          customerId: this.$formDetails.state.data.id,
        },
      };
      dealingsList(data).then((res) => {
        this.dealingsList = res.data.list;
      });
    },

    // 添加说明
    onAddExplain(i, item) {
      this.dialogVisibles = true;
      let that = this;
      setTimeout(() => {
        this.$refs.explain.getIntercourseId(item);
      }, 50);
    },
    handleClose() {
      this.dialogVisibles = false;
      this.getDealings(this.btnP);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.Dealings-box {
  height: 100%;
  overflow: auto;
  padding: 0 30px;
}
.add-but {
  padding: 0px 0 15px 70px;
  flex: 1;
  overflow: auto;
  height: 100%;
  .line {
    padding-bottom: 16px;
    position: relative;
    span:nth-child(1) {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      display: inline-block;
      width: 60px;
    }
    span:nth-child(2) {
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      margin-left: 34px;
    }
  }
  .Circle {
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    background: #2370eb;
    border: 2px solid #2370eb;
    left: -30px;
    top: 6px;
    border-radius: 50%;
  }
  .describe {
    padding-bottom: 16px;
    span:nth-child(1) {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      display: inline-block;
      width: 60px;
    }
    span:nth-child(2) {
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      margin-left: 34px;
    }
  }
}
.Dealings_list {
  border-bottom: 1px dashed #ccc;
  margin-bottom: 10px;
  padding: 10px 0;
}
</style>
