<template>
  <div class="form">
    <div class="title">
      <p>所有联系人</p>
      <span>
        <el-button
          type="primary"
          v-if="isOperation"
          icon="el-icon-plus"
          size="mini"
          @click="onAddForm"
        >
          添加联系人</el-button
        >
      </span>
    </div>
    <div
      class="contactsList"
      v-for="(form, index) in customerContactList"
      :key="index"
    >
      <el-form
        label-width="90px"
        label-position="left"
        size="small"
        style=" padding: 10px 23px;"
      >
        <el-form-item label="联系人">
          <span class="text">{{ form.contactName }}</span>
        </el-form-item>
        <el-form-item label="联系方式">
          <p
            v-if="form.customerContactWayDOList"
            v-for="item in form.customerContactWayDOList"
            style="margin-bottom:10px"
          >
            <span class="text" style="width:70px;margin-right:10px">{{
              contactWay(item.contactWay)
            }}</span>
            <span
              class="text"
              style="width:140px; overflow: hidden;white-space: nowrap; text-overflow: ellipsis;"
              >{{ item.contactContent }}</span
            >
          </p>
        </el-form-item>
        <el-form-item label="联系人标签">
          <span
            :class="item.category == 0 ? 'blue' : 'yellow'"
            class="labelList"
            v-for="item in form.contactTagList"
            :key="item.id"
            >{{ item.tagName }}
          </span>
        </el-form-item>
        <div v-if="form.fold">
          <el-form-item label="性别">
            <span class="text">{{
              form.sex == 1 ? "男" : form.sex == 2 ? "女" : "-  -"
            }}</span>
          </el-form-item>
          <el-form-item label="部门">
            <span class="text">{{ form.department }}</span>
          </el-form-item>
          <el-form-item label="职务">
            <span class="text">{{ form.duty }}</span>
          </el-form-item>
          <el-form-item label="公司">
            <span class="text">{{ form.companyName }}</span>
          </el-form-item>
          <el-form-item label="生日">
            <span class="text">{{ form.birthday }}</span>
          </el-form-item>
          <el-form-item label="备注">
            <span class="text">{{ form.remark }}</span>
          </el-form-item>
        </div>
      </el-form>
      <div class="bottom-but">
        <span class="fold" @click="onFold(form)">
          <span v-if="!form.fold"
            >展开
            <i class="el-icon-arrow-down"></i>
          </span>
          <span v-if="form.fold">收起 <i class="el-icon-arrow-up"></i></span>
        </span>
        <span class="but">
          <el-button
            type="text"
            style="color:#2370EB;"
            @click="onEdit(form)"
            v-if="isOperation"
            >编辑</el-button
          >
          <span
            style="margin:0 10px;color:#2370EB"
            v-if="customerContactList.length > 1 && isOperation"
            >|</span
          >
          <el-button
            type="text"
            style="color:#2370EB;"
            @click="onDelete(form)"
            v-if="customerContactList.length > 1 && isOperation"
            >删除</el-button
          ></span
        >
      </div>
    </div>
    <!-- 删除联系人 -->
    <el-dialog
      :visible.sync="dialogDelete"
      width="560px"
      :before-close="handleClose"
      append-to-body
    >
      <template slot="title">
        <div
          style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
        >
          删除联系人
        </div>
      </template>
      <div class="Delcontent">
        是否删除该联系人
      </div>
      <div class="but-bottom">
        <el-button
          round
          type="primary"
          size="mini"
          style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
          @click="onClose"
          >取消</el-button
        >
        <el-button
          round
          type="primary"
          style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
          size="mini"
          @click="onSubmit"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { contactList } from "@/api/user/ae/customer";
import { customerDelete } from "@/api/customer/customer";
import { relationList, relationDelete } from "@/api/user/label.js";
export default {
  data() {
    return {
      customerContactList: [],
      dialogDelete: false,
      delData: "",
      isOperation: "",
    };
  },
  methods: {
    getData(row, type) {
      this.isOperation = type;
      let data = {
        param: { customerId: this.$formDetails.state.data.id },
      };
      contactList(data).then((res) => {
        this.getLabelData(res.data.list);
      });
    },
    // 获取绑定标签
    getLabelData(list) {
      if (list.length > 0) {
        for (let i = 0; i < list.length; i++) {
          list[i].fold = false;
          let data = {
            param: {
              bandingId: list[i].id,
              bandingTableType: "4",
            },
          };
          relationList(data).then((res) => {
            list[i].contactTagList = res.data.list;
            let that = this;
            setTimeout(() => {
              that.customerContactList = list;
            }, 70);
          });
        }
      }
    },
    onFold(form) {
      form.fold = !form.fold;
    },
    // 添加联系人
    onAddForm() {
      this.$choiceLabel.commit("emptyData");
      this.$emit("addContacts");
    },
    // 编辑联系人
    onEdit(row) {
      sessionStorage.setItem(
        "storage",
        JSON.stringify(this.$formDetails.state.data)
      );
      this.$emit("editContacts", row);
    },
    // 删除联系人
    onDelete(row) {
      this.dialogDelete = true;
      this.delData = row;
    },
    onClose() {
      this.dialogDelete = false;
    },
    onSubmit() {
      let data = {
        param: {
          id: this.delData.id,
        },
      };
      customerDelete(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("删除成功");
          this.handleClose();
        }
      });
    },
    handleClose() {
      this.dialogDelete = false;
      this.$emit("deleteContacts");
    },
    contactWay(data) {
      if (data == 1) {
        return "电话";
      } else if (data == 2) {
        return "微信";
      } else if (data == 3) {
        return "邮箱";
      } else if (data == 4) {
        return "QQ";
      } else if (data == 5) {
        return "短信";
      } else if (data == 6) {
        return "拜访";
      } else if (data == 7) {
        return "其他";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.form {
  padding: 20px;
  .title {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 15px;
    p {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
    }
    button {
      background: #fff;
      border-color: #2370eb;
      color: #2370eb;
    }
  }
  .contactsList {
    border-radius: 7px;
    border: 1px solid #e5e5e5;
    margin-top: 16px;
  }
  .text {
    display: inline-block;
    width: 230px;
    padding-left: 10px;
    background: #f9f9f9;
    overflow: hidden;
    white-space: nowrap;
    /*当文本溢出包含元素时，以省略号表示超出的文本*/
    text-overflow: ellipsis;
  }
  .labelList {
    display: inline-block;
    padding: 0 8px;
    height: 30px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 30px;
    margin-right: 32px;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
  }
  .blue {
    color: #2370eb;
    border-color: #2370eb;
    .cancel {
      border-left: 10px solid #2370eb;
    }
  }
  .yellow {
    color: #fa8c15;
    border-color: #fa8c15;
    .cancel {
      border-left: 10px solid #fa8c15;
    }
  }
  .bottom-but {
    text-align: center;
    font-size: 14px;
    border-top: 1px solid #eeeeee;
    .fold {
      cursor: pointer;
      display: inline-block;
      padding: 12px 0;
      color: rgb(35, 112, 235);
    }
    .but {
      float: right;
      margin-right: 10px;
    }
  }
}
.Delcontent {
  padding: 100px 80px;
  font-size: 16px;
}
.but-bottom {
  width: 100%;
  height: 48px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  background: #fff;
  justify-content: space-between;
  button {
    width: 72px;
  }
}
</style>
