<template>
    <div class="table">
        <el-table
            v-if="active == '潜在客户'"
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            key="1"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '13px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="6"> </el-table-column>

            <el-table-column label="创建时间" show-overflow-tooltip width="90">
                <template slot-scope="scope">
                    <span>{{
                        $tableDataHandle.createTime({
                            createTime: scope.row.createTime,
                        })
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="客户名称" width="230">
                <template slot="header">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;客户名称</div>
                </template>
                <template slot-scope="{ row, $index }" style="overflow: hidden">
                    <div class="customer_name">
                        <i
                            :class="
                                row.topStatus == 1
                                    ? 'iconfont icon-shoucang'
                                    : 'iconfont icon-star'
                            "
                            v-if="btnP.topping"
                            @click.stop="onTopping(row)"
                            style="margin-right: 9px; font-size: 12px"
                        ></i>
                        <div style="overflow: hidden">
                            <el-button
                                type="text"
                                style="
                                    color: #333333;
                                    display: block;
                                    font-size: 12px;
                                "
                                @click="onDetails(row, $index)"
                            >
                                {{ row.companyName }}</el-button
                            >
                            <el-popover
                                placement="bottom"
                                trigger="hover"
                                :close-delay="100"
                            >
                                <div class="tag_list">
                                    <span
                                        :class="
                                            item.category == 0
                                                ? 'blue'
                                                : 'yellow'
                                        "
                                        v-for="item in row.tagList"
                                        >{{ item.tagName }}</span
                                    >
                                </div>
                                <p slot="reference">
                                    <span
                                        :class="
                                            item.category == 0
                                                ? 'blue'
                                                : 'yellow'
                                        "
                                        v-for="item in row.tagList"
                                        >{{ item.tagName }}</span
                                    >
                                </p>
                            </el-popover>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="经营状态" show-overflow-tooltip width="70">
                <template slot-scope="scope">
                    <span
                        v-if="
                            scope.row.enterpriseName &&
                            scope.row.enterpriseName.companyStatus
                        "
                        >{{
                            returnCompanyStatus(
                                scope.row.enterpriseName.companyStatus
                            )
                        }}</span
                    >
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="法人" show-overflow-tooltip width="60">
                <template slot-scope="scope">
                    <span
                        v-if="
                            scope.row.enterpriseName &&
                            scope.row.enterpriseName.legalPerson
                        "
                        >{{ scope.row.enterpriseName.legalPerson }}</span
                    >
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="所在地" width="100" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-popover
                        v-if="scope.row.enterpriseName"
                        placement="bottom"
                        width="auto"
                        trigger="hover"
                    >
                        <div class="companyDomain">
                            <p>
                                {{ scope.row.enterpriseName.companyAddress }}
                            </p>
                        </div>
                        <span slot="reference">
                            {{ returnAddress(scope.row.enterpriseName) }}
                        </span>
                    </el-popover>

                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="域名" width="155">
                <template slot-scope="scope" style="position: relative">
                    <el-popover
                        placement="bottom"
                        width="auto"
                        trigger="hover"
                        v-if="scope.row.companyDomain"
                    >
                        <div class="companyDomain">
                            <p>
                                {{ scope.row.companyDomain }}
                            </p>
                            <p
                                v-for="item in scope.row.customerDomainList"
                                :key="item.id"
                            >
                                {{ item.domain }}
                            </p>
                        </div>
                        <span slot="reference">
                            {{ scope.row.companyDomain }}
                        </span>
                    </el-popover>
                    <span v-else>- -</span>
                    <i
                        class="circle_toast"
                        style="right: 10px"
                        v-if="
                            scope.row.customerDomainList.length +
                                (scope.row.companyDomain ? 1 : 0) >
                            1
                        "
                        >{{
                            scope.row.customerDomainList.length +
                            (scope.row.companyDomain ? 1 : 0)
                        }}</i
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>

            <el-table-column label="联系人" width="105">
                <template slot-scope="scope" style="position: relative">
                    <el-popover placement="bottom" width="auto" trigger="hover">
                        <div class="company_contact">
                            <p
                                v-for="item in scope.row
                                    .customerMainContactList"
                                :key="item.id"
                            >
                                <i :class="returnIcon(item)"></i>
                                <span> {{ returnContactName(item) }} </span
                                >{{ returnContact(item) }}
                            </p>
                            <p
                                v-for="item in scope.row.customerContactList"
                                :key="item.id"
                            >
                                <i :class="returnViceIcon(item)"></i>
                                <span> {{ returnContactName(item) }} </span
                                >{{ returnViceContact(item) }}
                            </p>
                        </div>
                        <span
                            slot="reference"
                            v-if="
                                scope.row.customerMainContactList &&
                                scope.row.customerMainContactList.length > 0
                            "
                        >
                            <i
                                :class="
                                    returnIcon(
                                        scope.row.customerMainContactList[0]
                                    )
                                "
                            ></i
                            >{{
                                returnContact(
                                    scope.row.customerMainContactList[0]
                                )
                            }}
                        </span>
                        <span
                            slot="reference"
                            v-if="
                                scope.row.customerMainContactList &&
                                scope.row.customerMainContactList.length == 0 &&
                                scope.row.customerContactList &&
                                scope.row.customerContactList.length > 0
                            "
                        >
                            <i
                                :class="
                                    returnViceIcon(
                                        scope.row.customerContactList[0]
                                    )
                                "
                            ></i
                            >{{
                                returnViceContact(
                                    scope.row.customerContactList[0]
                                )
                            }}
                        </span>
                    </el-popover>
                    <i
                        class="circle_toast"
                        v-if="
                            scope.row.customerMainContactList.length +
                                scope.row.customerContactList.length >
                            1
                        "
                        >{{
                            scope.row.customerMainContactList.length +
                            scope.row.customerContactList.length
                        }}</i
                    >
                </template>
            </el-table-column>

            <el-table-column min-width="20"> </el-table-column>

            <el-table-column label="类型" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        <i
                            class="iconfont icon-renzheng"
                            v-if="
                                scope.row.enterpriseType == 1 &&
                                scope.row.enterpriseName &&
                                scope.row.enterpriseName.aiChaStatus == 1
                            "
                        ></i>
                        <!-- 
                            " -->
                        <span>{{
                            scope.row.enterpriseType === 1
                                ? '企业'
                                : scope.row.enterpriseType === 2
                                ? '个人'
                                : scope.row.enterpriseType === 3
                                ? '外资'
                                : '- -'
                        }}</span>
                        <span style="margin-left: 10px">{{
                            scope.row.customerStatus === 0 ? '潜在' : '成交'
                        }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="操作" fixed="right" width="140">
                <template slot="header">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;操作</div>
                </template>
                <template slot-scope="scope">
                    <i
                        class="iconfont icon-shijian01"
                        v-if="returnDealingsTime(scope.row)"
                    ></i>
                    <el-button
                        type="text"
                        size="samll"
                        style="
                            margin-left: 13px;
                            color: #2370eb;
                            font-size: 12px;
                        "
                        @click="addDealings(scope.row)"
                        v-if="btnP.cusDealings"
                        >往来{{ returnDealingsLen(scope.row) }}</el-button
                    >
                    <el-button
                        type="text"
                        size="samll"
                        style="color: #2370eb; font-size: 12px"
                        v-if="btnP.cusClue"
                        @click="onAddClue(scope.row)"
                        >线索</el-button
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <el-table
            v-if="active != '潜在客户'"
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            key="1"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '13px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="6"> </el-table-column>

            <el-table-column label="创建时间" show-overflow-tooltip width="90">
                <template slot-scope="scope">
                    <span>{{
                        $tableDataHandle.createTime({
                            createTime: scope.row.createTime,
                        })
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="客户名称" width="230">
                <template slot="header">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;客户名称</div>
                </template>
                <template slot-scope="{ row, $index }" style="overflow: hidden">
                    <div class="customer_name">
                        <i
                            :class="
                                row.topStatus == 1
                                    ? 'iconfont icon-shoucang'
                                    : 'iconfont icon-star'
                            "
                            v-if="btnP.topping"
                            @click.stop="onTopping(row)"
                            style="margin-right: 9px; font-size: 12px"
                        ></i>
                        <div style="overflow: hidden">
                            <el-button
                                type="text"
                                style="
                                    color: #333333;
                                    display: block;
                                    font-size: 12px;
                                "
                                @click="onDetails(row, $index)"
                            >
                                {{ row.companyName }}</el-button
                            >
                            <el-popover
                                placement="bottom"
                                trigger="hover"
                                :close-delay="100"
                            >
                                <div class="tag_list">
                                    <span
                                        :class="
                                            item.category == 0
                                                ? 'blue'
                                                : 'yellow'
                                        "
                                        v-for="item in row.tagList"
                                        >{{ item.tagName }}</span
                                    >
                                </div>
                                <p slot="reference">
                                    <span
                                        :class="
                                            item.category == 0
                                                ? 'blue'
                                                : 'yellow'
                                        "
                                        v-for="item in row.tagList"
                                        >{{ item.tagName }}</span
                                    >
                                </p>
                            </el-popover>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="经营状态" show-overflow-tooltip width="70">
                <template slot-scope="scope">
                    <span
                        v-if="
                            scope.row.enterpriseName &&
                            scope.row.enterpriseName.companyStatus
                        "
                        >{{
                            returnCompanyStatus(
                                scope.row.enterpriseName.companyStatus
                            )
                        }}</span
                    >
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="法人" show-overflow-tooltip width="60">
                <template slot-scope="scope">
                    <span
                        v-if="
                            scope.row.enterpriseName &&
                            scope.row.enterpriseName.legalPerson
                        "
                        >{{ scope.row.enterpriseName.legalPerson }}</span
                    >
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="所在地" width="100" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-popover
                        v-if="scope.row.enterpriseName"
                        placement="bottom"
                        width="auto"
                        trigger="hover"
                    >
                        <div class="companyDomain">
                            <p>
                                {{ scope.row.enterpriseName.companyAddress }}
                            </p>
                        </div>
                        <span slot="reference">
                            {{ returnAddress(scope.row.enterpriseName) }}
                        </span>
                    </el-popover>
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="域名" width="155">
                <template slot-scope="scope" style="position: relative">
                    <el-popover
                        placement="bottom"
                        width="auto"
                        trigger="hover"
                        v-if="scope.row.companyDomain"
                    >
                        <div class="companyDomain">
                            <p>
                                {{ scope.row.companyDomain }}
                            </p>
                            <p
                                v-for="item in scope.row.customerDomainList"
                                :key="item.id"
                            >
                                {{ item.domain }}
                            </p>
                        </div>
                        <span slot="reference">
                            {{ scope.row.companyDomain }}
                        </span>
                    </el-popover>
                    <span v-else>- -</span>
                    <i
                        class="circle_toast"
                        style="right: 10px"
                        v-if="
                            scope.row.customerDomainList.length +
                                (scope.row.companyDomain ? 1 : 0) >
                            1
                        "
                        >{{
                            scope.row.customerDomainList.length +
                            (scope.row.companyDomain ? 1 : 0)
                        }}</i
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>

            <el-table-column label="联系人" width="105">
                <template slot-scope="scope" style="position: relative">
                    <el-popover placement="bottom" width="auto" trigger="hover">
                        <div class="company_contact">
                            <p
                                v-for="item in scope.row
                                    .customerMainContactList"
                                :key="item.id"
                            >
                                <i :class="returnIcon(item)"></i>
                                <span> {{ returnContactName(item) }} </span
                                >{{ returnContact(item) }}
                            </p>
                            <p
                                v-for="item in scope.row.customerContactList"
                                :key="item.id"
                            >
                                <i :class="returnViceIcon(item)"></i>
                                <span> {{ returnContactName(item) }} </span
                                >{{ returnViceContact(item) }}
                            </p>
                        </div>
                        <span
                            slot="reference"
                            v-if="
                                scope.row.customerMainContactList &&
                                scope.row.customerMainContactList.length > 0
                            "
                        >
                            <i
                                :class="
                                    returnIcon(
                                        scope.row.customerMainContactList[0]
                                    )
                                "
                            ></i
                            >{{
                                returnContact(
                                    scope.row.customerMainContactList[0]
                                )
                            }}
                        </span>
                        <span
                            slot="reference"
                            v-if="
                                scope.row.customerMainContactList &&
                                scope.row.customerMainContactList.length == 0 &&
                                scope.row.customerContactList &&
                                scope.row.customerContactList.length > 0
                            "
                        >
                            <i
                                :class="
                                    returnViceIcon(
                                        scope.row.customerContactList[0]
                                    )
                                "
                            ></i
                            >{{
                                returnViceContact(
                                    scope.row.customerContactList[0]
                                )
                            }}
                        </span>
                    </el-popover>
                    <i
                        class="circle_toast"
                        v-if="
                            scope.row.customerMainContactList.length +
                                scope.row.customerContactList.length >
                            1
                        "
                        >{{
                            scope.row.customerMainContactList.length +
                            scope.row.customerContactList.length
                        }}</i
                    >
                </template>
            </el-table-column>

            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="交易概述" width="140" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div class="deal_summary">
                        <span
                            class="ssspan"
                            v-if="scope.row.relateBO"
                            style="margin-right: 8px"
                            @click="toConlist(scope.row)"
                        >
                            {{
                                '合同' +
                                (scope.row.relateBO.contractCount > 0
                                    ? '(' +
                                      scope.row.relateBO.contractCount +
                                      ')'
                                    : '')
                            }}
                        </span>
                        <span
                            class="ssspan"
                            v-if="
                                scope.row.relateBO &&
                                scope.row.relateBO.orderCount
                            "
                            @click="toOrder(scope.row)"
                        >
                            {{
                                '订单' +
                                (scope.row.relateBO.orderCount > 0
                                    ? '(' + scope.row.relateBO.orderCount + ')'
                                    : '')
                            }}
                        </span>
                        <el-popover
                            v-else-if="
                                returnBusinessNumber(scope.row.relateBO) > 0
                            "
                            placement="bottom"
                            width="auto"
                            trigger="hover"
                        >
                            <div class="deal_summary_popover">
                                <span @click="toMailBox(scope.row, 1)"
                                    >腾讯({{
                                        scope.row.relateBO.chanceNicheBO
                                            .aliCount
                                    }})</span
                                >
                                <span
                                    style="margin: 0 10px"
                                    @click="toMailBox(scope.row, 2)"
                                    >阿里({{
                                        scope.row.relateBO.chanceNicheBO
                                            .tencentCount
                                    }})</span
                                >
                                <span @click="toMailBox(scope.row, 4)"
                                    >网易({{
                                        scope.row.relateBO.chanceNicheBO
                                            .neteaseCount
                                    }})</span
                                >
                            </div>
                            <span slot="reference" class="ssspan">
                                {{
                                    '商机' +
                                    (returnBusinessNumber(scope.row.relateBO) >
                                    0
                                        ? '(' +
                                          returnBusinessNumber(
                                              scope.row.relateBO
                                          ) +
                                          ')'
                                        : '')
                                }}
                            </span>
                        </el-popover>
                        <span v-else class="ssspan">
                            {{
                                '商机' +
                                (returnBusinessNumber(scope.row.relateBO) > 0
                                    ? '(' +
                                      returnBusinessNumber(scope.row.relateBO) +
                                      ')'
                                    : '')
                            }}
                        </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="类型" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        <i
                            class="iconfont icon-renzheng"
                            v-if="
                                scope.row.enterpriseType == 1 &&
                                scope.row.enterpriseName &&
                                scope.row.enterpriseName.aiChaStatus == 1
                            "
                        ></i>
                        <!-- 
                            " -->
                        <span>{{
                            scope.row.enterpriseType === 1
                                ? '企业'
                                : scope.row.enterpriseType === 2
                                ? '个人'
                                : scope.row.enterpriseType === 3
                                ? '外资'
                                : '- -'
                        }}</span>
                        <span style="margin-left: 10px">{{
                            scope.row.customerStatus === 0 ? '潜在' : '成交'
                        }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="操作" fixed="right" width="140">
                <template slot="header">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;操作</div>
                </template>
                <template slot-scope="scope">
                    <i
                        class="iconfont icon-shijian01"
                        v-if="returnDealingsTime(scope.row)"
                    ></i>
                    <el-button
                        type="text"
                        size="samll"
                        style="
                            margin-left: 13px;
                            color: #2370eb;
                            font-size: 12px;
                        "
                        @click="addDealings(scope.row)"
                        v-if="btnP.cusDealings"
                        >往来{{ returnDealingsLen(scope.row) }}</el-button
                    >
                    <el-button
                        type="text"
                        size="samll"
                        style="color: #2370eb; font-size: 12px"
                        v-if="btnP.cusClue"
                        @click="onAddClue(scope.row)"
                        >线索</el-button
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <!-- 往来记录 -->
        <el-drawer
            :visible.sync="drawerDealings"
            :direction="direction"
            :before-close="handleClose"
            size="880px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    往来记录
                </div>
            </template>
            <Dealings @close="handleClose" ref="dealings" />
        </el-drawer>
        <!-- 编辑 -->
        <el-drawer
            :visible.sync="drawerDetails"
            :direction="direction"
            :before-close="handleClose"
            size="880px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    {{ drawerTitle }}
                </div>
            </template>
            <Details @close="handleClose" ref="details" />
        </el-drawer>
        <!-- 新建线索 -->
        <el-drawer
            :visible.sync="drawerAddClue"
            :direction="direction"
            :before-close="handleAddClue"
            size="560px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    客户新增线索
                </div>
            </template>
            <AddClue
                @close="handleAddClue"
                ref="addClue"
                @onSubmit="onSubmitClue"
            />
        </el-drawer>
    </div>
</template>
<script>
import { customerList } from '@/api/customer/customer.js';
import { customerTop, editCustomer } from '@/api/user/ae/customer';
import { addClue } from '@/api/user/clue.js';
import Dealings from '../../dealings/dealings.vue';
import { clueAdd } from '@/api/clue/meClue.js';
import Details from '../../details/details.vue';
import { getCustomerId } from '@/api/user/ae/customer.js';
import AddClue from '../../../database/addClue/addClue.vue';
export default {
    components: {
        Dealings,
        Details,
        AddClue,
    },
    data() {
        return {
            tableData: [],
            btnP: {},
            drawerDealings: false,
            drawerDetails: false,
            direction: 'rtl',
            drawerAddClue: false,
            drawerTitle: '',
            pageData: {},
            active: '',
        };
    },
    methods: {
        getData(searchVal, btnP, active) {
            this.pageData = searchVal;
            this.active = active;
            console.log(searchVal);
            customerList(searchVal).then((res) => {
                this.tableData = res.data.list;
                this.$emit('totalNub', res.data.total);
                this.btnP = btnP;
            });
        },
        // 置顶
        onTopping(row) {
            let data = {
                param: {
                    id: row.customerAdminId,
                },
            };
            if (row.topStatus == 0) {
                data.param.topStatus = 1;
            } else {
                data.param.topStatus = 0;
            }
            customerTop(data).then((res) => {
                if (row.topStatus == 0) {
                    if (res.code == 200) {
                        this.$message.success('标记成功');
                    }
                } else {
                    if (res.code == 200) {
                        this.$message.success('取消成功');
                    }
                }

                this.$emit('getData');
            });
        },

        onDetails(row, index) {
            sessionStorage.setItem('cusIndex', index);
            let btn = JSON.parse(sessionStorage.getItem('isButArr'));
            let type = false;
            for (let i in btn) {
                if (btn[i].children) {
                    for (let key in btn[i].children) {
                        if (btn[i].children[key].route == 'edit') {
                            type = true;
                            break;
                        }
                    }
                    break;
                }
                if (btn[i].route == 'edit') {
                    type = true;
                    break;
                }
            }
            if (type) {
                this.$router.push({
                    path: '/customerdetails',
                    query: {
                        type: type,
                        isCus: 1,
                    },
                });
            } else {
                this.$router.push({
                    path: '/customerdetails',
                    query: {
                        isCus: 1,
                    },
                });
            }
            this.$formDetails.commit('detailsData', JSON.stringify(row));
            this.$choiceLabel.commit('getType', '2');
            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
        },
        // 添加往来
        addDealings(row) {
            this.drawerDealings = true;
            setTimeout(() => {
                this.$refs.dealings.getData(row);
            }, 0);
        },
        // 生成线索
        onAddClue(row) {
            let data = {
                param: {
                    id: row.id,
                },
            };
            getCustomerId(data).then((res) => {
                if (res.code == 200) {
                    this.drawerAddClue = true;
                    setTimeout(() => {
                        this.$refs.addClue.getData(res.data, this.btnP);
                    }, 0);
                }
            });
        },
        // 生成线索
        onSubmitClue(data) {
            clueAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleAddClue();
                }
            });
        },
        handleAddClue() {
            this.drawerAddClue = false;
            this.$emit('getData');
        },
        // 置顶
        onStarMark(row) {
            let data = {
                param: {
                    id: row.id,
                },
            };
            if (row.mark == 1) {
                data.param.mark = 0;
            } else if (row.mark == 0) {
                data.param.mark = 1;
            }
            libraryUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.getTableData();
                }
            });
        },

        handleClose() {
            this.drawerDealings = false;
            this.drawerDetails = false;
            this.$choiceLabel.commit('emptyData');
            this.$emit('getData');
        },

        // 原邮箱品牌
        primaryEmailBrand(row) {
            return this.$tableDataHandle.primaryEmailBrand(row);
        },
        onDelete(row) {
            let data = {
                param: {
                    customerDO: {
                        id: row.id,
                        status: 2,
                    },
                },
            };
            editCustomer(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.getData(this.pageData, this.btnP);
                }
            });
        },
        returnCompanyStatus(status) {
            const companyStatus = [
                '注册',
                '吊销',
                '在册',
                '在营',

                '解散',
                '注销',
                '开业',
                '撤销',
                '设立',
                '正常',
                '迁出',
                '存续',
            ];
            let returnText = '';
            companyStatus.forEach((item) => {
                if (status.indexOf(item) != -1) {
                    returnText = item;
                }
            });
            return returnText;
        },
        returnAddress(row) {
            return row.companyAddress
                ? row.companyAddress.slice(0, 6) + '...'
                : '- -';
        },
        returnContact(data) {
            if (data.contactContent) {
                return data.contactContent;
            }
        },
        returnViceContact(data) {
            if (
                data.customerContactWayDOList &&
                data.customerContactWayDOList.length > 0
            ) {
                return data.customerContactWayDOList[0].contactContent;
            }
        },
        returnContactName(data) {
            if (data.contactName) {
                return data.contactName;
            }
        },
        returnIcon(data) {
            var icon = 'iconfont ';
            if (data.contactWay) {
                switch (data.contactWay) {
                    case 1:
                        icon += 'icon-dianhua';
                        break;
                    case 2:
                        icon += 'icon-weixin';
                        break;
                    case 3:
                        icon += 'icon-youxiang';
                        break;
                    case 4:
                        icon += 'icon-QQ';
                        break;
                    case 5:
                        icon += 'icon-duanxinxiaoxixinxi';
                        break;
                    case 6:
                        icon += 'icon-yuyuebaifang';
                        break;
                    case 7:
                        icon += 'icon-qita1';
                        break;
                    case 8:
                        icon += 'icon-diannao';
                        break;
                    case 9:
                        icon += 'icon-zuoji';
                        break;
                }
                return icon;
            }
        },
        returnViceIcon(data) {
            var icon = 'iconfont ';
            if (
                data.customerContactWayDOList &&
                data.customerContactWayDOList.length > 0
            ) {
                switch (data.customerContactWayDOList[0].contactWay) {
                    case 1:
                        icon += 'icon-dianhua';
                        break;
                    case 2:
                        icon += 'icon-weixin';
                        break;
                    case 3:
                        icon += 'icon-youxiang';
                        break;
                    case 4:
                        icon += 'icon-QQ';
                        break;
                    case 5:
                        icon += 'icon-duanxinxiaoxixinxi';
                        break;
                    case 6:
                        icon += 'icon-yuyuebaifang';
                        break;
                    case 7:
                        icon += 'icon-qita1';
                        break;
                    case 8:
                        icon += 'icon-diannao';
                        break;
                    case 9:
                        icon += 'icon-zuoji';
                        break;
                }
                return icon;
            }
        },
        returnBusinessNumber(obj) {
            return (
                Number(obj.chanceNicheBO.aliCount) +
                Number(obj.chanceNicheBO.neteaseCount) +
                Number(obj.chanceNicheBO.tencentCount)
            );
        },
        toMailBox(row, type) {
            sessionStorage.setItem('companyName', row.companyName);
            if (type == 1) {
                this.$router.push({
                    path: '/tmreporting',
                });
            }
            if (type == 2) {
                this.$router.push({
                    path: '/alireporting',
                });
            }
            if (type == 4) {
                this.$router.push({
                    path: '/wyreporting',
                });
            }
        },
        returnDealingsTime(row) {
            return row.customerIntercourseList &&
                row.customerIntercourseList.length > 0
                ? row.customerIntercourseList.some((item) => {
                      return (
                          new Date().getTime() <=
                          new Date(item.createTime).getTime() +
                              3 * 24 * 60 * 60 * 1000
                      );
                  })
                : false;
        },
        returnDealingsLen(row) {
            return row.customerIntercourseList &&
                row.customerIntercourseList.length > 0
                ? '(' +
                      row.customerIntercourseList.filter((item) => {
                          return (
                              new Date().getTime() <=
                              new Date(item.createTime).getTime() +
                                  3 * 24 * 60 * 60 * 1000
                          );
                      }).length +
                      ')'
                : '';
        },
        toConlist(row) {
            if (row.relateBO.contractCount > 0) {
                sessionStorage.setItem('searchCompanyName', row.companyName);
                this.$router.push({
                    path: '/conlist',
                });
            }
        },
        toOrder(row) {
            if (row.relateBO.orderCount > 0) {
                sessionStorage.setItem('searchCompanyName', row.companyName);
                this.$router.push({
                    path: '/saleOrder',
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
/deep/ th:nth-child(3) .cell {
    padding-left: 30px;
}
.table {
    width: 100%;
    height: 100%;
}
/deep/ td:nth-child(4) {
    padding: 8px 0 !important;
}
.blue {
    color: #2370eb;
    font-size: 12px;
}
.yellow {
    color: #ff703b;
    font-size: 12px;
}
.icon-shoucang {
    color: #feb516;
}
.icon-star {
    color: #ccc;
}
.Times {
    height: 24px;
    background: #fff2ed;
    border-radius: 2px;
    border: 1px solid #ff7a44;
    display: inline-block;
    padding: 2px 5px;
    margin-right: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #ff7a45;
}
.customer_name {
    display: flex;
    align-items: center;
    > div {
        p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            span + span {
                margin-left: 7px;
            }
        }
    }
}
/deep/ .el-table__row td {
    font-size: 12px !important;
}
.companyDomain {
    font-size: 12px;
    p + p {
        margin-top: 10px;
    }
}
.icon-dianhua {
    color: #2370eb;
}
.icon-weixin {
    color: #50b674;
}
.icon-QQ {
    color: #2370eb;
}
.icon-youxiang {
    color: #feb516;
}
.icon-qita {
    color: #13ab16;
}
.icon-yuyuebaifang {
    color: #f15641;
}
.icon-qita1 {
    color: #af90ff;
}
.icon-zuoji {
    color: #6a58ca;
}
.icon-duanxinxiaoxixinxi {
    color: #13ab16;
}
.icon-diannao {
    color: #87b1e9;
}
.company_contact {
    .icon-dianhua,
    .icon-weixin,
    .icon-QQ,
    .icon-youxiang,
    .icon-qita,
    .icon-youxiang1,
    .icon-yuyuebaifang,
    .icon-qita1,
    .icon-diannao,
    .icon-duanxinxiaoxixinxi,
    .icon-zuoji {
        position: static;
        left: 0;
        top: 0;
    }
    p {
        span {
            display: inline-block;
            margin: 0 8px;
            width: 50px;
        }
    }
}
.icon-renzheng {
    position: absolute;
    left: -10px;
    top: 49%;
    transform: translateY(-50%);
    color: #f5a623;
}
.icon-shijian01 {
    position: absolute;
    left: 4px;
    top: 51%;
    transform: translateY(-50%);
    color: #bc70ff;
}
.deal_summary {
    span {
        cursor: pointer;
        color: #2370eb;
    }
    .ssspan {
        display: inline-block;
        width: 55px;
    }
}
.deal_summary_popover {
    font-size: 12px;
    span {
        display: inline-block;
        cursor: pointer;
        user-select: none;
    }
    .ssspan {
        display: inline-block;
        width: 55px;
    }
}
.circle_toast {
    font-size: 12px;
    display: inline-block;
    width: 12px;
    height: 12px;
    background: #f7b84f;
    color: #fff;
    line-height: 12px;
    border-radius: 50%;
    text-align: center;
    font-style: normal;
    position: absolute;
    right: 0;
    top: 49%;
    transform: translateY(-50%);
}
.tag_list {
    span + span {
        margin-left: 10px;
    }
}
</style>
